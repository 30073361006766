.banner_heading {
    font-size: 125px;
    font-weight: 400;
    color: #ffffff;
    position: relative;
}
.banner_heading:before {
    content: "Safe and Stable";
    position: absolute;
    font-size: 110px;
    font-weight: 400;
    color: #FFFFFF3B;
    bottom: -46px;
}
.banner_img {
    max-width: 522px;
}

.reasons img {
    width: 100px;
    height: 100px;
}
.reasons-para {
    font-size: 25px;
    color: #fff;
    font-weight: 400;
    padding-top: 16px;
}
.resons_circle {
    border: 8px solid #fff;
    border-radius: 50%;
    width: 456px;
    height: 456px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
}
.inner-section {
    margin-bottom: 160px;
    margin-top: 90px;
}

@media (max-width: 768px) {
    .banner_heading {
        font-size: 52px;
        text-align: center;
    }
    .banner-content {
        text-align: center;
    }
    .banner_heading:before {
        font-size: 52px;
    }
    .primary-btn {
        font-size: 32px;
        width: 220px;
        height: 60px;
        line-height: 68px;
        border-radius: 8px;
    }
    .home_page .secondary_heading {
        font-size: 20px;
    }
    .banner_para {
        font-size: 20px;
    }
    .banner_img {
        max-width: 432px;
    }
    .resons_circle {
        width: 280px;
        height: 280px;
    }
    .reasons img {
        width: 84px;
        height: 84px;
    }
    .reasons-para {
        font-size: 18px;
    }
}
@media (max-width: 1200px) and (min-width: 768px) {
    .home_page .secondary_heading {
        font-size: 50px;
    }
    .banner_heading {
        font-size: 105px;
        text-align: center;
    }  
    .banner_heading:before {
        font-size: 105px;
    }
    .banner-content {
        text-align: center;
    }
    .primary-btn {
        font-size: 35px;
        width: 386px;
        height: 77px;
        line-height: 88px;
    }
    .banner_img {
        max-width: 432px;
    }
    .resons_circle {
        width: 432px;
        height: 432px;
    }
 
}
@media (max-width: 1440px) and (min-width: 1200px) {
    .resons_circle {
        width: 356px;
        height: 356px;
    }
    }  
