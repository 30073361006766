.secondary_banner_heading {
    font-size: 90px;
    color: #DD6B2B;
    font-weight: 400;
    line-height: 1.2;
    font-family: 'Ramaraja', serif;
    position: relative;
    padding-bottom: 15px;
}
.heading-middle-text {
    font-size: 100px;
    color: #ffffff;
}
.download_page .tab_item {
    font-size: 50px;
    font-weight: 400;
    font-family: 'Ramaraja', serif;
    padding: 25px 0 12px;
    text-transform: inherit;
}
.download_spec li {
    list-style-type: disc;
    display: list-item;
    padding: 0;
    font-size: 40px;
    font-weight: 400;
    color: #fff;
    font-family: 'Ramaraja', serif;
}
.tab-heading {
    font-size: 60px;
    font-weight: 400;
    color: #fff;
    font-family: 'Ramaraja', serif;
}
.secondary-btn {
    font-size: 45px;
    font-weight: 400;
    font-family: 'Ramaraja', serif;
    color: #ED6F28;
    border: 5px solid #ED6F28;
    background: #0000;
    border-radius: 25px;
    padding: 6px 64px 0;
    cursor: pointer;
    line-height: 1.6;
}
.secondary-btn:hover {
    color: #ffffff;
    border-color: #ffffff;
}
.download-sahpe {
    position: relative;
}
.down_shape {
    position: absolute;
    bottom: -44px;
    z-index: -1;
}
.download_spec {
    padding-left: 28px;
}
@media (max-width: 1200px) and (min-width: 768px) { 
    .secondary_banner_heading, .heading-middle-text {
        font-size: 52px;
    }
    .download_page .tab_item {
        font-size: 25px;
    }
    .tab-heading {
        font-size: 45px;
    }
    .download_spec li {
        font-size: 30px;
    }
    .secondary-btn {
        font-size: 30px;
        padding: 12px 19px 9px;
    }
    .download_spec {
        padding-left: 25px;
    }
}
@media (max-width: 768px) {
    .secondary_banner_heading, .heading-middle-text {
        font-size: 38px;
    }
    .down_shape {
        bottom: 0;
    }
    .download_page .tab_item {
        font-size: 25px;
    }
    .tab-heading {
        font-size: 25px;
    }
    .download_spec li {
        font-size: 16px;
    }
    .secondary-btn {
        font-size: 20px;
        padding: 10px 20px 8px;
    }
    .download_spec {
        padding-left: 21px;
    }
}