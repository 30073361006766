.policy-content {
    font-size: 30px;
    font-weight: 400;
    color: #fff;
    line-height: 38.01px;
}
.policy-content p {
    font-size: 30px;
    font-weight: 400;
    color: #fff;
    line-height: 38.01px;
}
.policy-content li {
    font-size: 30px;
    font-weight: 400;
    color: #fff;
    line-height: 38.01px;
    font-family: 'Ramabhadra', sans-serif;
}
.how_to_delete_page {
    background: #ED6F28;
    position: relative;
}
.policy-vector {
    position: absolute;
    top: -1px;
    left: 0;
    width: 86%;
}
.release-date {
    font-size: 30px;
    font-weight: 400;
    font-family: 'Ramabhadra', sans-serif;
    color: #fff;
}
.contact_heading {
    font-size: 110px;
    color: #DD6B2B;
    font-weight: 400;
    line-height: 1.2;
    font-family: 'Ramaraja', serif;
    position: relative;
    padding-bottom: 15px;
}
@media (max-width: 1440px) and (min-width: 1200px) {
    .contact_heading {
        font-size: 90px;
    }
}
@media (max-width: 1200px) and (min-width: 1024px) {
    .policy-vector {
        width: 85%;
    }
    .contact_heading {
        font-size: 68px;
    }
}
@media (max-width: 1024px) and (min-width: 768px) {
    .policy-vector {
        width: 76%;
    }
    .contact_heading {
        font-size: 48px;
    }
  
    .policy-content, .policy-content li, .policy-content p {
        font-size: 20px;
        font-weight: 400;
        color: #fff;
        line-height: 38.01px;
    }
}
@media (max-width: 768px) {
    .contact_heading {
        font-size: 32px;
    }
    .policy-content, .policy-content li, .policy-content p {
        font-size: 18px;
    }
    .policy-vector {
        width: 96%;
    }
    .release-date {
        font-size: 18px;
    }

}

@media (max-width: 509px) {
    .policy-vector {
        width: 100%;
    }
    .contact_heading {
        font-size: 32px;
    }
}