.hvector_img {
    width: 134px;
    padding-left: 32px;
}
.contact_heading {
    font-size: 110px;
    color: #DD6B2B;
    font-weight: 400;
    line-height: 1.2;
    font-family: 'Ramaraja', serif;
    position: relative;
    padding-bottom: 15px;
}
.contact_page .banner-content {
    display: flex;
    align-items: baseline;
}
.contact_form_title{
    font-size: 30px;
    font-weight: 400;
    font-family: 'Ramaraja', serif;
    color: #fff;
}
.submit-btn {
    font-size: 30px;
    font-weight: 400;
    font-family: 'Ramaraja', serif;
    background: #ffffff;
    border-radius: 25px;
    border: none;
    width: 298px;
    height: 72px;
    line-height: 79px;
    cursor: pointer;
}
.enabled-btn:hover {
    color: #ED6F28;
}
.enabled-btn {
    color: #2D2C2C;
    cursor: pointer;
  }
  
  /* Disabled state */
  .disabled-btn {
    color: #2D2C2C59;
    cursor: not-allowed;
  }
@media (max-width: 1200px) and (min-width: 768px) { 
    .contact_heading {
        font-size: 65px;
    }
    .submit-btn {
        font-size: 21px;
        width: 164px;
        height: 40px;
        line-height: 47px;
    }
}
@media (max-width: 768px) {
    .contact_heading {
        font-size: 30px;
    }
    .hvector_img {
        width: 40px;
        padding-left: 11px;
    }
    .contact_form_title {
        font-size: 18px;
    }
    .submit-btn {
        font-size: 18px;
        width: 115px;
        height: 30px;
        line-height: 35px;
    }
}