* {
    margin: 0;
    padding: 0;
}
img {
    width: 100%;
}
ul, li {
    text-decoration: none;
    list-style: none;
}
a {
    text-decoration: none;
}
body {
    font-family: 'Ramaraja', serif !important;
    background-color: #2D2C2C !important;
}
p {
    font-family: 'Ramabhadra', sans-serif !important;
}
.primary-btn {
    font-size: 45px;
    font-weight: 400;
    font-family: 'Ramaraja', serif;
    color: #ED6F28;
    width: 434px;
    height: 86px;
    line-height: 95px;
    background: #1E1E1E;
    border-radius: 25px;
    border: none;
    cursor: pointer;
}
.primary-btn:hover {
    background: #ffffff;
}

.secondary_heading {
    font-size: 50px;
    font-weight: 400;
    color: #fff;
    font-family: 'Ramabhadra', sans-serif;
    position: relative;
}
.banner_para {
    font-size: 22px;
    font-weight: 400;
    color: #fff;
}
.loader-wrap {
    position: fixed;
    top: 0;
    left: 0;
    background: #000000ce;
    width: 100%;
    height: 100%;
    z-index: 9;
}
.loader {
    width: 100px;
    position: absolute;
    transform: translate(-50px, -50px);
    top: 50%;
    left: 50%;
}