.footer_logo {
    width: 220px;
}
.footer_inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 121px;
    padding-bottom: 121px;
}
.follow-text {
    font-size: 25px;
    font-weight: 400;
    color: #fff;
    padding-bottom: 6px;
}
.social-icon {
    margin-left: 38px;
}
.social-icon img {
    margin: 0 23px;
    width: 34px;
    height: 34px;
}
.footer {
    background: #000;
}
.copyright {
    display: flex;
    justify-content: space-between;
    color: #fff;
    font-size: 20px;
    padding: 26px 0;
}
.copyright a {
    color: #fff;
    font-family: 'Ramabhadra', sans-serif;
}
.policy {
    padding-left: 30px;
    white-space: nowrap;
}
.policy a {
    border-bottom: 2px solid #fff;
}
.hdc-account {
    display: flex;
    justify-content: center;
}

@media (max-width: 1200px) and (min-width: 768px) {
    .footer_logo {
        width: 188px;
    }
    .follow-text {
        font-size: 20px;
    }
    .social-icon img {
        width: 34px;
        height: 34px;
        margin: 0px 12px;
    }
}
@media (max-width: 1024px) {
    .copyright {
        flex-direction: column-reverse;
        text-align: center;
    }
    .policy {
        padding: 0 30px;
        line-height: 2;
    }
    .hdc-account {
        margin-bottom: 20px;
    }
}
@media (max-width: 768px) {

    .footer_logo {
        width: 141px;
    }
    .follow-text {
        font-size: 18px;
    }
    .social-icon img {
        width: 22px;
        height: 22px;
        margin: 0px 12px;
    }
    .social-icon {
        margin-left: 26px;
    }
    .copyright {
        font-size: 15px;
        flex-direction: column-reverse;
        text-align: center;
        padding: 38px 0;    
    }
    .copyright p {
        margin-top: 20px;
    }
    .footer_inner {
        display: inherit;
        text-align: center;
        padding-top: 60px;
        padding-bottom: 60px;
    }
    .hdc-account {
        margin-bottom: 7px;
        word-break: break-all;
        display: initial;
    }
}