.site_log {
    width: 144px;
}
.close_drawer {
    text-align: right;
    padding: 20px 20px 0px;
}
.nav_list span {
    font-family: 'Ramaraja', serif;
    font-size: 23px;
    font-weight: 400;
}
.nav_list {
    padding-left: 20px;
}
.active-link {
    color: #FF7D34 !important;
}
.listItem {
    color: #fff;
    font-size: 23px;
}
.listItem span {
    font-size: 23px;
    font-family: 'Ramaraja', serif;
}
.listItem:hover {
    color: #FF7D34;
}
.listItem-m {
    color: #fff;
}
.listItem-m:hover {
    color: #FF7D34;
}