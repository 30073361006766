.about_img {
    padding: 0 34px;
}
.inner_banner_heading {
    font-size: 110px;
    color: #ED6F28;
    font-weight: 400;
    line-height: 0.7;
    font-family: 'Ramaraja', serif;
    position: relative;
}
.heading_devider {
    position: relative;
    display: inline-block;
}
.heading_devider:before {
    content: "";
    position: absolute;
    height: 3px;
    width: 145%;
    background-color: #FFFFFF33;
    bottom: 0;
    transform: translate(-50%, -50%);
    top: 100%;
    left: 50%;
}
.secondary_para {
    font-size: 30px;
    font-weight: 400;
    line-height: 1.8;
    color: #fff;
}

.para_devider {
    position: relative;
}
.para_devider:before {
    content: "";
    position: absolute;
    height: 3px;
    width: 621px;
    background-color: #FFFFFF33;
    bottom: 0;
    transform: translate(-50%, -50%);
    top: 100%;
    left: 50%;
}
.banner-para {
    font-size: 35px;
    font-weight: 400;
    color: #ED6F28;
}
@media (max-width: 1200px) and (min-width: 768px) { 
    .inner_banner_heading {
        font-size: 65px;
    }
    .secondary_heading {
        font-size: 35px;
    }
    .secondary_para, .banner-para {
        font-size: 20px;
    }
}
@media (max-width: 768px) {
    .inner_banner_heading {
        font-size: 40px;
    }
    .secondary_heading {
        font-size: 30px;
    }
    .secondary_para, .banner-para {
        font-size: 18px;
    }
    .heading_devider:before {
        width: 110%;
    }
    .para_devider:before {
        width: 300px;
    }
    .about_img {
        padding: 0px 2px;
    }
}